.verify-email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  padding: 20px;
}

.verify-email-container h1 {
  font-size: 24px;
  color: #333333;
}