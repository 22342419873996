
  ::-webkit-scrollbar {
    display: none;
  }
  .body__container {
    width: 100%;
    margin: 2rem auto;
    /* border: 1px solid var(--softer-color); */
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .navigation__api {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 10px var(--softer-color);
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;  /* Зафиксировать элемент сверху */
    background-color: var(--color-white);
    max-width: 600px;

  }
  .nav-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .nav{
    padding-top: 50px;
  }
  @media screen and (max-width: 1040px) {
    .body__container {
      width: 100%;
    }
    .nav {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 400px) {
    .body__container {
      width: 100%;
    }
    .nav-right {
      flex-direction: column;
    }
  }
  @media print {
    .nav {
      display: none;
    }
  }
  .add__products{
    max-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
  }

  .active-category {
    display: block;
    width: 100%;
  }
  .foto{
    padding-top: 30px;
  }
  
.foto__title{

    padding: 50px 201px;
    background-color: #f9a92c;
    clip-path: polygon(0 0, 25% 10%, 75% 0, 100% 10%, 100% 85%, 75% 100%, 25% 90%, 0 100%);
    color: #000;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 30px;
}
.foto__img{
  width: 250px;
  height: 250px;
  border-radius: 15px;
 object-fit: cover;
}
.images-gallery{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.foto__description{

  font-family: Montserrat;
  font-size: 12px;
  margin: 0 auto;
  max-width: 230px;
  text-align: center;

}
.foto__btn{
  padding: 0;
  border: none;
  background-color: transparent;


}
.like-icon{
  height: 33px;
  position: absolute;
  right: 5px;
  top: 225px;
  width: 33px;
}
.image-item{
  position: relative;
}
.foto__sum{
  position: absolute;
  top: 249px;
  right: 5px;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: bold;
}
@media (min-width: 320px) and (max-width: 500px) {
  .foto__title{
    font-size: 16px;
   
    padding: 40px 0;
  }
  .foto__img{
    width: 250px;
    height: 250px;
  }

}
@media (min-width: 500px) and (max-width: 800px) {
  .foto__title{
    font-size: 18px;
   
    padding: 40px 0;
  }

}