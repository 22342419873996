
.arron-service__context{
    display: flex;
}

.arron-service__content{
  max-width: 1200px;
  margin: 0 auto;
}
.arron-service{
display: flex;
gap: 125px;
justify-content: center;
padding-top: 50px;
padding-bottom: 20px;
background: transparent;

border-radius: 15px;
}
.arron-service__img{
  width: 50px;
  height: 50px;
}
.arron-service__title-text{
    color: #13171D;
    font-family: Bebas Neue;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
}
.arron-service__list{
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
}
  
  .arron-service__list-item {
    position: relative;
    color: #000000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 133.333% */
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  


.arron-service__title{
    color: #13171D;
font-family: Bebas Neue;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
width: 427px;
height: 157px;
flex-shrink: 0;
}

.arron-servise_text{
  margin: 0 auto;
  max-width: 115px;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  line-height: normal;
}
.about__image-arron-service{
    width: 35px;
    height: 45px;
}
.arron-service__subtitle{
    color: #000;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 288px;

flex-shrink: 0;
}

@media (max-width: 320px) {
  .arron-service{
flex-direction: column;
gap: 40px;
  }
  .arron-service__content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .arron-service__title{
    color: #13171D;
text-align: center;
font-family: Bebas Neue;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
max-width: 300px;
margin: 0 auto;

  }
  .arron-service__subtitle{
    font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
max-width: 239px;
margin: 0 auto;
  }
  .arron-service__btn{
    max-width: 242px;
    max-height: 50px;
    color: #FFF;
    text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 26px;
  }
  .arron-service__list{
    margin: 0 auto;
    padding: 0;
    display: flex;
    max-width: 270px;
  }
  .arron-service__title-text{
    text-align: center;
font-family: Bebas Neue;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;

  }
  .arron-service__list-item{
    font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
padding-bottom: 5px;
padding-left: 8px;
  }
  .arron-service__bullet{
    top: 44%;
  }
  
}

@media screen and (min-width: 320px) and (max-width: 449px){
.arron-service{
  display: flex;
  max-width: 100%;
  padding-top: 5px;
}
.arron-service__list{
  display: flex;
  margin: 0 auto;
  padding: 0;
gap: 20px;
margin: 0 auto;
justify-content: center;
max-width: 370px;

}
.arron-service__content {
  flex-direction: column;
  display: flex;

  justify-content: center;
}
.arron-service__title-text{
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
font-family: Bebas Neue;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 100% */
}
.arron-service__bullet{
  top: 33%;
}
.arron-service__list-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 140px;
  margin: 0 auto;
  color: #444;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
padding: 0;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */

}
.arron-service__img{
  max-width: 50px;
  text-align: center;
  line-height: 1;
}
.arron-servise_text{
  font-size: 8px;
  max-width: 100px;
  line-height: normal;
}
}
@media screen and (min-width: 450px) and (max-width: 768px){
.arron-service__title-text{
  text-align: center;
padding-bottom: 40px;
  margin: 0 auto;
}
.arron-service__content{
  max-width: 400px;
}
.arron-service__list{
  padding: 0;
  margin: 0 auto;
  gap: 25px;
}
.arron-service__bullet{
  top: 12px;

}
.arron-service__list-item{
  line-height: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.arron-servise_text{
  font-size: 10px;
  font-weight: 600;

}
.arron-service__img{
  width: 35px;
  height: 35px;

}
}
