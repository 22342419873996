.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  
  .modal__content {
    position: relative;
    background: white;
    padding: 129px;
    z-index: 1001;
    max-width: 800px;
    border-radius: 20px;
  }
  .create__form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: #f9a92d30;
   width: 700px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 30px;
  }
  .modal__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .create__btn{
    border: none;
    padding: 10px;
    color: white;
    background-color: #f9a92c;
    border-radius: 10px;
    margin-right: 10px;
    width: 70%;
  }
  .admin__input{
    padding: 10px;
    border-radius: 5px;
    border: none;
        width: -webkit-fill-available;
  }