.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
}

.modal-content {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  border-radius: 4px;
}

.cancel {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.cancel:hover,
.cancel:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.caption {

  bottom: 15px;
  left: 0;
  right: 0;
  text-align: center;
  color: #ccc;
  padding: 10px;
  font-size: 18px;
  background-color: rgba(0,0,0,0.6);
}

@media only screen and (max-width: 700px) {
  .modal-content {

        width: 80%;
        height: 50%;
        object-fit: contain;
        padding: 5px;
  }
  
  .cancel {
    top: 10px;
    right: 25px;
    font-size: 30px;
  }
  
  .caption {
    font-size: 16px;
    
  }
}