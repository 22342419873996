.icon-content_soshial{
  position: fixed;
  margin: 0 auto;
}
.form__input {
  width: 300px;
  padding: 0 40px 0;
  height: 46px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #e6e6e6;
  border: none;
  border-radius: 10px;
  outline: none;
  color: rgb(0, 0, 0);
}
.form__title{
text-align: center;
}
.error1 {
font-size: 10px;
    color: red;
    position: absolute;
    width: 400px;
    top: 82px;
    left: -90px;
}
.error3{
  font-size: 14px;
  color: red;
  position: absolute;
  width: 400px;
  top: 250px;
    left: 126px;
}
.form {
  margin: 20px auto 0;
  display: flex;
  flex-direction: column;
  max-width: 396px;
  color: white;
  align-items: center;
}
.form-overlay__btn {
  display: flex;
  max-width: 420px;
  padding: 18px 45px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 15px;
  background-color: #e6e6e6;
  border: none;
  margin: 20px auto;

}
.form__label {
  margin: 20px 0 10px 0;
  font-size: 10px;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 21px;
  width: 320px;
}

.form-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Минимальная высота контейнера равна высоте видимой части страницы */
  z-index: 99;
  width: 100%;
  background: #FFF;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}
.form-container{
  max-width: 505px;
  width: 100%;
  box-sizing: border-box;
  background: white;
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  border-radius: 10px;
  padding: 20px 40px;
}

.form__text{
  border: 1px solid rgb(0, 0, 0);
  max-width: 324px;
  height: 84px;
}
.besplatnaya {
  color: red;
}
.close-button{
  font-size: 0;
    box-shadow: none;
    color: white;
    cursor: pointer;
    border: none;
    max-width: 30px;
    width: 100%;
    min-height: 32px;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s ease;
    background-repeat: no-repeat;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: -30px;
}


@media screen and (max-width: 490px) {
  .form-overlay__btn{
    margin: 5px auto 20px;
 
    padding: 15px 30px;
  }
  .form{
margin: 0 auto;
  }
  .form__label-text{
    margin-left: 20px;
    margin-right: 20px;
    max-width: 200px;
  }
  .form__text{
    max-width: 250px;
  }
.form-container{
  max-width: 280px;
  top: 50%;
}
.form__title{
  font-size: 18px;
  margin: 10px auto 0 auto;
  text-align: center;

}
.form__label{
  margin: 10px auto 5px auto;
  max-width: 200px;
  width: 250px;
}
.form__input{
  max-width: 200px;
  width: 200px;
  font-size: 10px;
  height: 40px;
  padding: 0 30px 0;
}
.close-button{
  top: -35px;
  right: -5px;
}
.error1 {
font-size: 9px;
    position: absolute;
    top: 70px;
    left: 49%;
    transform: translateX(-50%);
    width: 400px;
}
.error3 {
  font-size: 10px;
  position: absolute;
  top: 170px;
  left: 90%;
  transform: translateX(-50%);
  width: 400px;
}
}
@media screen and (min-width: 491px) and (max-width: 768px) {
.form-container{
  max-width: 400px;
}
.error1{
    top: 82px;
    left: -87px;
    font-size: 11px;

}
.error3{
  top: 287px;
  left: 100px;
}
}