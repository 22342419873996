.shop-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  position: relative;
}

.shopcategory-banner {
  width: 80%;
  max-width: 1200px;
  height: auto;
  margin-bottom: 20px;
}

.shopcategory-indexSort {
    margin-bottom: 20px;
    display: flex;
    position: relative;
    width: 190px;
    height: 60px;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    border-color: var(--color-primary);
}

/* Основная сетка */
.shopcategory-products {
      display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    justify-items: center;
}
.shopcategory-input{
  width: 300px;
    padding: 11px 15px;
    border-color: var(--color-primary);
    border-radius: 0.5rem;
}
.shopcategory-loadmore {
  margin-top: 20px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  width: 100px;
}

/* Адаптивные стили для более крупных экранов */
@media screen and (min-width: 768px) {


  .shopcategory-banner {
    margin-bottom: 30px;
  }
}

/* Адаптивные стили для десктопных экранов */
@media screen and (min-width: 1024px) {
  
}

/* Стили для отдельного элемента товара */
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.item:hover {
  transform: translateY(-5px);
}

.item img {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-bottom: 15px;
  object-fit: cover;
}

.item-title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
      width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
}

.item-prices {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.item-price-new {
  font-size: 16px;
  font-weight: bold;
  color: #e60000;
}

.item-price-old {
  font-size: 14px;
  font-weight: normal;
  text-decoration: line-through;
  color: #555;
}
@media screen and (max-width: 480px) {
    .shopcategory-products {
        grid-template-columns: 1fr;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 481px) {
    .item img{
       max-width: 200px;
    }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
    .shopcategory-products {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 768px) {

  
}

@media screen and (min-width: 1024px) {
    .cshopcategory-products {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}