.image-list {
  padding: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.image-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.image-icon__item{
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.image-info {
  padding: 10px;
}

.image-info p {
  margin: 0 0 10px 0;
}

