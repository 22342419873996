.gifts{

    border-radius: 20px;
    padding-bottom: 50px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    background: transparent;
    backdrop-filter: blur(3px);
    border-radius: 15px;
}
.gifts__title{

    padding-bottom: 10px;
}

.form__title{
    margin: 0 auto;
    text-align: center;
    color: #13171D;
    font-family: Bebas Neue;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.form__with {
    position: relative;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    max-width: 396px;
    color: white;
    align-items: center;
    padding-bottom: 20px;

}
.form__container{
  padding: 30px;
  box-shadow: 1px 1px 20px skyblue;
        /* position: relative; */
        border-radius: 20px;
}
.form-btn{
display: flex;
max-width: 420px;
padding: 18px 45px;
justify-content: center;
align-items: flex-start;
gap: 10px;
border-radius: 15px;
background-color: #e6e6e6;
border: 1px solid black;
margin: 0 auto;
margin: 0 auto;
transition: all 0.18s ease;
backdrop-filter: blur(40px);
backface-visibility: hidden;

}
.form-btn:hover{
inset: 100%;
background: conic-gradient(from calc(270deg-(var(--glow-spead)*0.5)),
var(glow)0,
var(--glow) var(--glow-spead),
transparent var(--glow-spead));
translate: 0 0;
transform: rotate(0deg);
width: auto;
animation: spin calc(var(--speed)*2s)infinite linear;
animation-play-state: paused;
}
.form__label {
    margin: 20px 0 10px 0;
    font-size: 10px;
    color: #000000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 21px;
  }
  .close-button{
    font-size: 0;
      box-shadow: none;
      color: white;
      cursor: pointer;
      border: none;
      max-width: 30px;
      width: 100%;
      min-height: 32px;
      background-color: rgba(0, 0, 0, 0);
      transition: 0.2s ease;
      background-repeat: no-repeat;
      font-weight: 400;
      position: absolute;
      right: 0;
      top: -30px;
  }
  .error2 {
font-size: 10px;
    color: red;
    position: absolute;
    width: 200px;
    top: 79px;
    left: 32px;
  }
  .error4{
  font-size: 10px;
    color: red;
    position: absolute;
    width: 200px;
    top: 175px;
    left: 25px;
  }

  @media screen and (max-width: 490px) {
    .form-btn {
        margin: 5px auto 20px;
        padding: 15px 30px;
      }
      

    .form{
  margin: 0 auto;
    }
    .form__label-text{
      margin-left: 20px;
      margin-right: 20px;
      max-width: 200px;
    }
    .form__text{
      max-width: 250px;
    }
  .form__container{
    max-width: 280px;
    top: 50%;
  }
  .form__title{
    font-size: 18px;
    margin: 10px auto 0 auto;
    text-align: center;
  
  }
  .form__label{
    margin: 10px auto 5px auto;
    max-width: 200px;
  }
  .form__input{
    max-width: 200px;
    width: 200px;
    font-size: 10px;
    height: 40px;
    padding: 0 30px 0;
  }
  .close-button{
    top: -35px;
    right: -5px;
  }
  .error2 {
    font-size: 9px;
    position: absolute;
    top: 69px;
    left: 50%;
    transform: translateX(-50%);
    width: 125px;
  }
  .error4 {
font-size: 9px;
    position: absolute;
    top: 149px;
    left: 50%;
    transform: translateX(-50%);
    width: 139px;
  }
  }
  @media screen and (min-width: 491px) and (max-width: 768px) {
  .form__container{
    max-width: 400px;
  }
  .gifts__title{
    padding-top: 40px;
    font-size: 26px;
    line-height: normal;
    grid-row: 5/2;
    justify-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
    box-shadow: none;
    text-transform: none;
}
  .error2{
 top: 77px;
    left: 54px;
    width: 164px;
    font-size: 11px;

  }
  .error4{
  top: 177px;
    width: 200px;
    left: 16px;
    font-size: 11px;
  }
  }
  @media screen and (min-width: 320px) and (max-width: 490px) {
    .gifts__title{
    
    font-family: Bebas Neue;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    max-width: 268px;
    padding-bottom: 20px;
    text-align: center;
    box-shadow: none;
    text-transform: none;
    }
  }




/*стили новой формы */
.form__container {
 width: 400px;
  background: #F8F9FD;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
  margin: 20px;
}

.heading {
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  color: rgb(16, 137, 211);
}

.form {
  margin-top: 20px;
  position: relative;
}

.form .input {
  width: 100%;
  background: white;
  border: none;
  padding: 15px 20px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline: 2px solid transparent;
}

.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
}

.form .input:focus {
  outline: none;
  border-inline: 2px solid #12B1D1;
}

.form .forgot-password {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form .forgot-password a {
  font-size: 11px;
  color: #0099ff;
  text-decoration: none;
}

.form .login-button {
  display: block;
  width: 100%;
  font-weight: bold;
  background: linear-gradient(45deg, rgb(16, 137, 211) 0%, rgb(18, 177, 209) 100%);
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.social-account-container {
  margin-top: 25px;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
  fill: white;
  margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.agreement {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.agreement a {
  text-decoration: none;
  color: #0099ff;
  font-size: 9px;
}
.example{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  bottom: 20%;
  right: 10px;
  z-index: 1000;
}
.icon-content_soshial{
  position: fixed;
  margin: 0 auto;
}