.item{
    width: 300px;
}
.item p{
    margin: 6px 0;
}
.item-title{
    font-family: Gilroy;
    font-size: 18px;
}
.item-prices{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.item:hover{
    transform: scale(1.05s);
    transition: 0.6s;
}
@media screen and (min-width: 451px) and (max-width: 600px) {
    .item-title{
        font-size: 14px;
    }
}
@media screen and (min-width: 320px) and (max-width: 450px) {
    .item-title{
        font-size: 12px;
    }
    .item{
    width: 250px;
}
}