.footer__links {

background-color: #004578;
    clip-path: polygon(0% -4%, 29% 5%, 58% 2%, 100% 12%, 101% 78%, 100% 100%, 12% 98%, 0% 100%);
    padding-bottom: 50px;
    color: #fff;

}
.contacts{
    position: relative;
   
}
.contact-info__numberone{
  color: white;

}
.contacts__list-info{
  display: flex;
  flex-direction: column;
}
.contacts-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: white;
    max-width: 700px;
    margin: 0 auto;
    padding: 0 20px 40px;
  }
  
  @media (min-width: 768px) {
    .contacts-text {
      font-size: 18px;
      margin-bottom: 30px;
      max-width: 500px;
    }
  }
  
  @media (min-width: 1024px) {
    .contacts-text {
      font-size: 20px;
      margin-bottom: 40px;
      max-width: 700px;
    }
  }
  
  @media (min-width: 1440px) {
    .contacts-text {
      font-size: 22px;
      margin-bottom: 50px;
    }
  }
  
  .contacts-text p {
    margin-bottom: 15px;
    text-align: justify; /* Добавляем выравнивание по ширине */
  }