.map-card{
        color: black;
    background-color:#f9aa2c;
    border-radius: 15px;
    font-family: Bebas Neue;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    display: flex;
    flex-direction:column;
    gap: 7px;
    margin:0 auto;
}