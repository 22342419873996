.footer{
  padding: 30px;
padding-top: 20px;
position: relative;
z-index: 2;
background: linear-gradient(to bottom right, #003366, #006699);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
}
.footer__map {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;

  border-radius: 5px;
}

.footer__map-card{
  width: 100%;
  /* height: 100%; */
  /* border: 0; */
  border-right: 1px solid white;
  border-radius: 20px;
}
.footer__map-card{
border-right: 2px solid currentColor;
}

.footer__info{

  border-radius: 6px;
  padding: 20px;
  width: 80%;
  text-align: center;
  background: transparent;

}
.oplata__title{
    color: #ffffff;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    line-height: 57px;
    text-align: center;
}
.oplata__list{
  list-style: none;
  text-decoration: none;
  display: flex;
  align-items: center;
 padding: 0;
  justify-content: center;
  gap: 30px;
  padding-top: 30px;
}
.oplata__img{
  width: 150px;
 
}
.oplata__image{
 width: 500px;
 height: 100px;
}
.footer__title{
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.footer__author{
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin: 10px auto;
}
.footer__link{
  text-decoration: none;
  list-style: none;
  padding-bottom: 20px;
  text-align: center;
  font-size: 12px;
  margin: 0 auto;
  color: #ffffff;
}
.footer__link:hover {
  text-decoration: underline;
  color: #F9A92C;
}

@media screen and (min-width: 320px) and (max-width: 499px){
  .footer__info{
    width: 100%;
  }
  .footer__title{
    font-size: 12px;
  }
  .footer__map{
    flex-direction: column;
    align-items: center;
  }
  .footer__map-card{
    width: 100%;
    margin-top: -10px;
  }
  .footer__map-card{
    border-bottom: 2px solid currentColor;
    border-right: none;
    }

    .oplata__title{
      color: #ffffff;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
    }
    .oplata__list{
      list-style: none;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      justify-content: center;
      margin: 0 auto;
    }
    .oplata__img{
      width: 120px;
      height: 100px;
     
    }
    .oplata__image{
      width: 320px;
      height: 100px;
      object-fit: contain;
    }
}
@media screen and (min-width: 500px) and (max-width: 900px){
  .footer__title{
    font-size: 14px;
    text-align: center;
  }
  .oplata__title{
        color: #ffffff;
        font-family: Montserrat;
        font-size: 23px;
        font-style: normal;
        font-weight: bold;
        line-height: 51px;
        text-align: center;
        padding-bottom: 30px;
  }
  .oplata__list{
    list-style: none;
    text-decoration: none;
    display: flex;
flex-wrap: wrap;
    margin: 0 auto;
    padding: 0;
  }
  .oplata__img{
    width: 120px;
    height: 100px;
   
  }
  .oplata__image{
 width: 400px;
 height: 100px;

  }
    .footer__map{
    flex-direction: column;
    align-items: center;
  }

}