.chat__wrap {
  position: fixed;
  bottom: -400px;
  right: -450px;
  width: 400px;
  max-width: 95%; /* адаптивность */
  height: 500px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}



#open-chat-button {
    position: fixed;
    bottom: 39px;
    right: 20px;
    z-index: 999;
    background-color: transparent !important;
    border: none;
    /* border-radius: 50%; */
    padding: -10px;
    cursor: pointer;
    color: #b00d0d;
    /* font-size: 11px; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
  
    /* overflow: hidden; */

}

#open-chat-button::before, #open-chat-button::after {
content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background-color: rgba(0, 132, 255, 0.5);
    top: 22px;
    right: 58px;
    /* left: 0; */
     z-index: -1; 
    animation: pulse 1.5s infinite ease-in-out;
}

#open-chat-button::after {
  animation-delay: 0.75s; /* Задержка для второго круга */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

#open-chat-button:hover {
  background-color: #005bb5;
}
.img-chat{
  width: 200px;
  height: 200px;
}

.chat__wrap.open {
    right: 20px;
    position: fixed;
    bottom: 40px;
    width: 30%;
    border: 1px solid gray;
}
.chat__title {
 background: linear-gradient(to bottom right, #003366, #006699);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');
  color: white;
  padding: 15px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat__close {
  cursor: pointer;
  font-size: 20px;
}

/* 4. Тело Чата */
.chat__body {
  flex: 1;
  padding: 15px;
  background-color: var(--chat-body-bg);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat__body__item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  position: relative;
}

.chat__body__item__user__icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat__body__item__user {
  font-weight: bold;
  color: var(--text-color);
}

.chat__body__item__text {
  background-color: var(--user-msg-bg);
  color: var(--text-color);
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 70%;

  position: relative;
}

.chat__body__item__manager .chat__body__item__text {
  background-color: var(--manager-msg-bg);
}

.chat__body__item__time {
  font-size: 0.8rem;
  color: var(--time-color);
  margin-top: 5px;
  align-self: flex-end;
}

.chat__body__item__delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 1rem;
  color: var(--time-color);
  visibility: hidden;
  transition: visibility var(--transition-speed), color var(--transition-speed);
}

.chat__body__item:hover .chat__body__item__delete {
  visibility: visible;
}

.chat__body__item__delete:hover {
  color: red;
}
.chat__body__item__client{

    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 15px;
    justify-content: flex-end;
     border-radius: 10px;
    background-color: #54b0dc;
}
.chat__body__item .chat__body__item__client{
      display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 15px;
    justify-content: flex-end;
   
}
.chat__body__item__manager{
  background-color: #f9aa2c;
  border-radius: 10px;
}
.chat__body__item {
display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 15px;
        overflow-wrap: anywhere;
        padding: 5px;

  

}

.chat__body__item__user__icon {
    width: 49px;
    height: 49px;
    border-radius: 50%;

}

.chat__body__item__content {
  background: #e1e1e1;
  padding: 10px;
  border-radius: 10px;
  max-width: 85%;
}

.chat__body__item__client .chat__body__item__content {
  background: #a0d1ff; /* Цвет для сообщений клиента */
  margin-left: auto; /* Размещение сообщений клиента справа */
}

.chat__body__item__manager .chat__body__item__content {
  background: #d3d3d3; /* Цвет для сообщений менеджера */
}

.chat__body__item__text {
  font-size: 14px;
}

.chat__body__item__user {
     font-weight: bold;
    font-size: 15px;
    font-style: italic;
 
}

.chat__body__item__time {
font-size: 12px;
    color: #ffffff;
    margin-left: 5px;
    margin-top: 32px;
    margin-right: 5px;
    word-wrap: break-word;
}
.chat__input__message{
    width: 100%;
    border: 1px solid;
    margin-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
      border: 1px solid #ccc;

}

.chat__input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e1e1e1;
}

.chat__main__input {
    flex-grow: 1;
  
    border-radius: 5px;
    padding: 12px;
    font-size: 13px;
    font-weight: 600;
    resize: none;
    /* display: flex; */
    margin-right: 7px;
      
}

.chat__input__submit {
  background: transparent;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 14px;
  width: 100%;
    border: 1px solid #ccc;
  height: 40px;
  width: 50px;
}



.chat__body__item__delete {
  cursor: pointer;
  margin-left: 10px;
  color: red;
  margin-right: 20px;
}
@media (max-width:768px) {
    .chat__wrap {
     bottom: 10px;
position: fixed !important;
        right: -500px;
     width: 60%;
     height: 50%;
     background: #fff;
     z-index: 99999999;
     box-shadow: none;
 
     }
     .img-chat {
width: 143px;
height: 143px;
     }
.chat__body__item__delete{
margin-right: 0px;
}
.chat__body__item__user{
  font-size: 10px;
}
.chat__body__item__text{
  font-size: 12px;
}
.chat__body__item__user__icon {
  width: 30px;
  height: 30px;
}
.chat__input{
  flex-direction: column;
  gap: 5px;
}
.chat__input__submit{
         width: 100%;
}
.chat__input__message {
  margin-right: 0px;
}
.chat__wrap.open {
  width: 80%;
}
#open-chat-button::before, #open-chat-button::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background-color: rgba(0, 132, 255, 0.5);
    top: 12px;
    right: 33px;
    /* left: 0; */
    z-index: -1;
    animation: pulse 1.5s infinite ease-in-out;
}

}
