.contactsRef {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px auto;
  background-color: #004578;
    clip-path: polygon(0% -4%, 29% 5%, 58% 2%, 100% 12%, 101% 78%, 100% 100%, 12% 98%, 0% 100%);
    padding-bottom: 50px;
  }


  .contacts__content {
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.contacts__icon-sochial{
    list-style: none;
}
.contacts__block{
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  gap: 10px;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
  color:rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    margin: 0;
    
    padding: 10px 15px;
}
.contactsRef {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
color: rgb(255, 255, 255);

 background-color: #004578;
    clip-path: polygon(0% -4%, 29% 5%, 58% 2%, 100% 12%, 101% 78%, 100% 100%, 12% 98%, 0% 100%);
 
}

.contacts__list-text{
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
  padding-bottom: 10px;
  color: #ffffff;
  font-family: Bebas Neue;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contacts__list-title{
  margin: 0 auto;
}
.contacts__text{
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #f9a92c;
}
.contacts__text:hover {
  color: #F9A92C;
}
.contacts__block-sochial{

  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border-bottom: 1px solid #f9a92c;
}
.contacts__image-sochial{
  max-width: 50px;
  height: 50px;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0px auto;
  padding: 3px 6px;
}
.contacts__image{
  max-width: 30px;
  height: 30px;
  border: none;
  outline: none;
  box-shadow: none;
  margin: 0;
}
.contacts__icon{
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 15px auto;
  gap: 15px;
}

@media (max-width: 768px) {
    .contactsRef {
      padding: 10px;
    }
    .contacts__list-text{
font-size: 40px;
    }

}

@media (min-width: 320px) and (max-width: 500px) {
.contacts__info{
 flex-direction: column;
}
.contacts__block{
    padding: 0;
  }
.footer__contacts{
  text-align: center;
font-family: Bebas Neue;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.contacts__text:hover {
  color: #F9A92C;
}
.contacts__icon{
  display: none !important;
}

}