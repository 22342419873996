.cookie-message {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    z-index: 999;
  }
  
  .cookie-message__text {
    font-size: 16px;
    font-family: Gilroy;
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .cookie-message__button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .cookie-message__button:hover {
    background-color: #0056b3;
  }
  
  .cookie-message__button:focus {
    outline: none;
  }

  @media screen and (min-width: 320px) and (max-width: 480px) {
    .cookie-message__text {
      font-size: 12px;
    }
  }