.admin-panel{
    padding-top: 50px;
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    text-decoration: none;
    text-align: center;
    align-items: stretch;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    width: 1100px;
}
.admin__link{
    text-decoration: none;
    max-width: 300px;
    cursor: pointer;
    background-color: #F9A92C;
    padding: 10px;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    height: 40px;
}