.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  flex-direction: column;
}

.modal-content button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #ccc;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #999;
}
.modal__text{
    margin: 0 auto;
    max-width: 200px;
    font-size: 14px;
    text-align: center;
    color: black;
}