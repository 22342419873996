.service__content{
  width: 95%;
  padding: 120px 0 0;
}

.service__title{
  color: #13171D;
  font-family: Bebas Neue;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 auto;
  padding-top: 60px;
  text-align: center;
}


.card_one {
  perspective: 1000px;
  width: 100%; 
  max-width: 300px;
  height: 400px; 

}

.cards {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
      background: #ebf6fb;
  border-radius: 5px;
}

.cards:hover {
  transform: rotateY(180deg);
}

.cover-item, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  
}

.cover-item {
  background: #ffffff;
  color: rgb(255, 255, 255);
}

.card__title-one {
  top: 75%;
  margin-bottom: 15px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  bottom: 55px;
  left: 50px;
  color: rgb(29, 29, 29);
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  z-index: 3;
 max-width: 150px;
  transform: translateZ(0px);
}
.card__title-one{
  filter: drop-shadow(3px 3px 10px white);
}
.card-cover {
  max-width: 100%;
  height: auto;
  max-height: 200px;
}

.card-back {
  transform: rotateY(180deg);

  color: white;
}

.card__subtitle-one {
 margin-bottom: 15px;
    font-size: 12px;
    color: black;
    text-align: center;
    /* padding: 5px; */
    width: 200px;

}
.cards:hover .cover-item, .card__title-one {
  transform: translateZ(100px);
}
.cover-item {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: ease all 2.3s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.cover-item:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  transition:
   ease all 1.3s;
  background: #ffffff29;
  border-radius: 5px;
}

.cards, .cover-item:before {
  content: "";
  position: absolute;
  border: 1px solid rgb(225, 214, 214);

  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  transition: ease all 2.3s;
  transform-style: preserve-3d;
  transform: translateZ(0px);
  border-radius: 5px;
}
.link-card {
  display: block;
  width: 80%;
  font-weight: bold;
  background: linear-gradient(45deg, rgb(16, 137, 211) 0%, rgb(18, 177, 209) 100%);
  color: rgb(0, 0, 0);
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 5px;
 
  border: none;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .card_one {
    max-width: 100%;
    height: 300px; 
  }

  .card__title-one, .card__subtitle-one {
    font-size: 18px;
  }

  .link-card {
    padding: 8px 16px;
    font-size: 14px;
  }
}
@media screen and (max-width: 790px) and (min-width: 500px) {
  .card-cover{
    width: 60%;
  }

  card__title-one{
    font-size: 0.9em;
  }
  .cards .cover-item{
    height: 100%;
  }
  .cards .card-back .link-card{
    top: 220px;
  }

  .card__subtitle-one{
    font-size: 0.6em;
    font-weight: normal;
  

  }


  .cards {
    position: relative;
    transition: ease all 1.3s;
    perspective: 1000px;
    transform-style: preserve-3d;
    height: 100%;
}
.card_one {
  align-items: center;
  height: 250px;
  width: 40%;
  margin: 0;
  perspective: 1000px;
  position: relative;
}
.card .card-back .link-card{
  font-size: 14px;
  top: 155px;
  padding: 10px 10px;
}
.card__title-one{
font-size: 0.9em;
}
}

@media screen and (max-width: 500px) and (min-width: 400px) {
  .cards, .cover-item:before {
border: 3px solid #ffffff80;
        border-radius: 5px;
        bottom: 27px;
        left: 20px;
        border: 1px solid rgb(225, 214, 214);
        right: 20px;
        top: 20px;
        transform: translateZ(0);
        transform-style: preserve-3d;
        transition: all 2.3s ease;
}

  .card-cover{
  
    width: 45%;
  }
  .cards .cover-item{
          height: 100%;
        top: 0px;
  
  }
  .cards .card-back .link-card{
    top: 155px;
  }

  .card__subtitle-one{
    font-size: 0.6em;
    font-weight: normal;
  

  }
  .card_one{
    width: 62%;
    height: 240px;

}
.service__title{
  font-family: Bebas Neue;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto;
  padding-bottom: 50px;
}
}
@media screen and (max-width: 1280px) and (min-width: 956px) {
  .card_one{
    height: 300px;
    width: 30%;
  }

  .cards .card-back .link-card{
    top: 175px;
  }
  .card__subtitle-one{
font-size: 0.8em;
font-weight: normal;
text-align: center;
  }
  .card__title-one{
    position: fixed;
    top: 81%;
    left: 39px;
    font-size: 0.8em;
    font-size: -0.1em;
    max-width: 220px;
    font-weight: bold;
  
  }
}

@media screen and (max-width: 955px) and (min-width: 796px) {
  .card_one{
    height: 250px;
    width: 30%;
  }
  .card__subtitle-one{
font-size: 0.8em;
font-weight: normal;
text-align: center;
  }
  .cards .card-back {
    height: 57%;
    top: 0;
    width: 74%;
    left: 33px;
  }
  .cards .card-back .link-card{
    top: 155px;
    font-size: 16px;
    padding: 10px;
  }
  .card__title-one{
    position: fixed;
    top: 85%;
    left: 30px;
    font-size: 0.8em;
    z-index: 2;
    max-width: 220px;
    font-weight: bold;
  
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .cards{
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1.3s ease;
  }
  .card_one{
    height: 250px;
    width: 80%;
  }
  .service__title {
    font-size: 26px;
    padding-top: 35px;
  }

  .cards .card-back .link-card {
    top: 160px;
    padding: 5px;
    font-size: 0.7em;
  }
  .card__subtitle-one{
font-size: 0.6em;
font-weight: normal;
text-align: center;
  }
  .card__title-one{
    position: fixed;
    top: 61%;
    right: 8%;
    font-size: 0.9em;
    z-index: 2;
    width: 156px;
    font-weight: 600;
  
  }
}
@media screen and (min-width: 500px) and (max-width:796px) {
  .card_one{
    height: 250px;
    width: 45%;
  }
  .card__title-one{
position: fixed;
    top: 78%;
    font-size: 0.6em;
    z-index: 2;
    max-width: 150px;
    font-weight: bold;
  
  }
  .card__subtitle-one{
    font-size: 0.6em;
    font-weight: normal;

  }
  .cards .card-back .link-card {
    top: 149px;
  }
  .service__title{
  font-family: Bebas Neue;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto;
}
}

  @media screen and (max-width: 1215px) and (min-width: 950px) {
    .cards .card-back .link-card{
      top: 185px;
    }
    .cards .card-back {
      height: 60%;
      width: 78%;
      top: 50px;
      left: 32px;
      display: flex;
      justify-content: flex-start;
      padding: 10px;
    }
  }

@media screen and (max-width: 680px) and (min-width: 500px){

}


@media screen and (max-width: 501px) and (min-width: 400px){

.card__title-one{
  position: fixed;
  top: 78%;
  left: 30px;
  font-size: 0.8em;
  z-index: 2;
  max-width: 220px;
  font-weight: 600;

}
}
@media (min-width: 1281px) { 
.cards{
  width: 100%;
}
.card_one {
  align-items: center;
  height: 400px;
  width: 50%;
  margin: 0;
  perspective: 1000px;
  position: relative;
}
.card .card-back {
  height: 64%;
  top: 105px;
  width: 80%;
  left: 33px;
}

}
  
