html{
  scroll-behavior: smooth;
}
body{
  margin: 0;
}


.nav{
  position: sticky;
top: 0;
width: 100%;
height: 0px;

  
}
:root {
  --soft-color: rgba(0, 0, 0, 0.5);
  --softer-color: rgba(0, 0, 0, 0.2);
  --color-primary: #f9a92c;
  --color-waring: green;
  --color-white: #fff;
  --color-danger: #ff0000;
  --dur: all 550ms ease-in-out; 
  --dur-a: all 650ms ease-in-out;
  --dur-b: all 750ms ease-in-out;
}
root{
  box-sizing: border-box;
}
*,*::after,::before{
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
root{
  background: #F4F9FC;
  display: flex;
  height: 100%;
  flex-direction: column;
  min-width: 320px;
  margin: 0 auto;
  line-height: 1.15;
  max-width: 1280px;
  min-height: 100vh;
}

