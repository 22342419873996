table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
  }
  
  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  thead {
    background-color: #f4f4f4;
  }
  
  th {
    height: 50px;
  }
  
  tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  .other{
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    position: relative;
  }
  .other__title{
    padding: 50px 201px;
    background-color: #f9a92c;
    clip-path: polygon(0 0, 25% 10%, 75% 0, 100% 10%, 100% 85%, 75% 100%, 25% 90%, 0 100%);
    color: #000;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 30px;
  }
  .smaller-font {
    font-family: Gilroy;
    font-size: 0.8em;  
  }
  @media screen and (min-width: 320px) and (max-width: 400px) {
    .other__title{
      font-family: Gilroy;
      max-width: 300px;
      font-size: 13px;
      padding: 10px;
    }
    .smaller-font{
      font-family: Gilroy;
      font-size: 9px;
      text-align: center;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 401px) and (max-width: 768px) {
    .other__title{
      font-family: Gilroy;
      max-width: 300px;
      font-size: 16px;
      padding: 15px;
    }
  }