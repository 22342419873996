.breadcrum {
    display: flex;
    flex-direction: row;

    gap: 8px;
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 40px 0px;
    text-decoration: capitalize;
    position: relative;
    align-items: flex-start;

        padding: 0 10px 0 10px;
}
.breadcrum-img{
    width: 5px;
}
@media (min-width: 320px) and (max-width: 500px){
    .breadcrum {
        font-size: 7px;
        margin:0 0 30px 0;
    }
}
@media (min-width: 501px) and (max-width: 768px) {
  .breadcrum {
        font-size: 11px;
        margin: 0 0 50px 0;
    }
}