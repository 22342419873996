.notFound {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .notFound__title {
    margin: 0 auto;
    font-size: 140px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 267px;
    padding-top: 246px;
    
  }
  .notFound__subtitle {
    display: block;
    margin: 5px auto;
    width: 400px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .notFound__button {
    cursor: pointer;
    display: block;
    margin: 184px auto 0;
    padding-bottom: 60px;
    width: 400px;
    color: #4285f4;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    border: none;
  }
  .notFound__button :hover{
    opacity: .7;
  }
  
  @media screen and (max-width: 768px) {
    .notFound {
      padding: 165px 0 135px 0;
    }
    .notFound__title {
      margin: 0 auto;
      max-width: 267px;
      padding-top: 246px;
      display: block;
    }
    .notFound__subtitle {
      margin: 4px auto;
    }
  }
  @media screen and (max-width: 400px) {
    .notFound {
      margin: 0;
      padding: 70px 0 0 0;
    }
  
    .notFound__title {
      margin: 14px auto 0;
      max-width: 152px;
      padding-top: 246px;
      font-size: 80px;
    
    }
    .notFound__subtitle {
      margin: 10px auto;
      font-size: 12px;
      max-width: 160px;
    }
    .notFound__button {
      margin: 284px auto 0;
      width: 50px;
      font-size: 12px;
      padding: 0;
    }
  }