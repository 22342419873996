.polis__privacy{
  position: relative;
}
.privacy__title{
    color: #13171D;
    font-family: Bebas Neue;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
    padding-top: 50px;
    max-width: 1000px;
}
.privacy__subtitle{
    color: #000000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   max-width: 800px;
    margin: 0 auto;
    padding-bottom: 50px;
}
@media (max-width: 800px) {
    .privacy__subtitle{
        max-width: 600px;
    }

}

@media (max-width: 768px) {
    .privacy__title {
      font-size: 20px;
      max-width: 500px;
    }
  
    .privacy__subtitle {
      font-size: 14px;
      max-width: 500px;
    }
  }
  
  @media (max-width: 480px) {
    .privacy__title {
      font-size: 18px;
      max-width: 400px;
    }
  
    .privacy__subtitle {
      font-size: 12px;
      max-width: 300px;
    }
  }
  @media (max-width: 320px) {
    .privacy__subtitle {
    max-width: 250px;
  }
}

.back__btn {
  position: fixed;
  top: 50%;
  right: 17px;
  /* left: 0; */
  transform: translate(50%, 50%) rotate(90deg);
  z-index: 3;
}

.back__btn ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.back__btn li {
      background: linear-gradient(to bottom right, #ffffff, #00aaff);
    clip-path: path('M 0 0 L 100% 0 C 100% 50%, 0 100%, 0 100% Z');

  color: #000;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.back__btn li a {
  color: #000000;
  text-decoration: none;
}