.burger-menu {
  display: none;
}

@media (max-width: 768px) {
  .burger-menu {
    display: block;
    position: relative;
  }

.nav-menu-brg{
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
}
  .burger-icon::before,
.burger-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 123, 255, 0.5); /* Синий цвет с некоторой прозрачностью */
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  z-index: 999;
}

.burger-icon::after {
  transition-delay: 0.1s;
}

.burger-icon:hover::before,
.burger-icon:hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

  .content {
    position: fixed;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    background-color: #041e42;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  }

  .open .content {
    transform: translateX(0);
  }

  .close-btn {
    max-width: 50px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #333;
    cursor: pointer;
    z-index: 999;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .logo-burger {
    display: flex;
    max-width: 90px;
padding: 5px;
    margin: 0 auto;
    justify-content: center;
    filter: drop-shadow(2px 1px 2px white);
    margin-top: 15px;
    background: white;
    border-radius: 10px;
  
  }

  .burger-menu__list {
    margin-top: 50px;
    padding: 0;
    list-style: none;
    color: white;
  }

  .burger-menu__item {
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
  }
  .burger-contacts {
    padding-top: 20px;
  }

  .nav__button{
    margin: 0 auto;
  display: flex;
  justify-content: center;
  border-radius: 10%;
  }

  .burger-menu__item a {
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }
.open .overlay {
    display: block;
  }
  .middle__links {
    background-color: rgb(211, 211, 211);
    padding: 10px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-decoration: none;
}
.header__links-burger{
  color: white;
  padding: 0 0 15px;
}
}

@media (max-width: 320px) {
  .content{
  width: 90%;
  }
  .contact-text{
    font-size: 11px;
    font-weight: bold;
  }
}