.about-me__title::before,
.about-me__title::after {
  font-size: 48px;
  color: red;
}
.about-me{
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.about-me__title {
  display: inline-block;
  color: #13171D;
text-align: center;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
max-width: 700px;
margin:0 30px
}
.about-me__subtitle{
  color: #646464;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  margin: 0 auto;
}
.about-me__image{
  width: 600px;
  height: 400px;
        max-height: fit-content;
object-fit: cover;
border-radius: 10px;
}
.about-me__content{
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
 margin: 0 auto;
 align-items: center;
}
.about-me__text{
  display: flex;
  padding-top: 80px;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin: 0 auto;
}
.about-me__about{

    padding-top: 80px;
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    gap: 30px;
    padding-bottom: 30px;
}
.about-me__next-button{
  display: inline-flex;
padding: 12px;
align-items: flex-start;
gap: 10px;
border-radius: 23px;
background: #FFF;
}


.about-me__gallery-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: -20px;
  right: -20px;
  transform: translateY(-50%);

}

.about-me__prev-button,
.about-me__next-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

}

.about-me__prev-button img,
.about-me__next-button img {

  object-fit: contain;
}

.about-me__prev-button {
  margin-right: 10px;
}

.about-me__next-button {
  margin-left: 10px;
}

.about-me__dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.about-me__dot {
  width: 10px;
  height: 10px;
  background-color: #1295d3;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 15px;
}

.about-me__dot.active {
  background-color: #041e42;
}
.about-me__gallery{
position: relative;
margin: 0 auto;
}
.about-me__next-image{
width: 70px;
height: 70px;

}
.about-me__prev-image{
  width: 70px;
  height: 70px;

}
@media screen and (min-width: 400px) and (max-width: 767px) {
  .about-me{
    padding-top: 40px;
  }
  .about-me__title{
    font-size: 18px;
    font-weight: bold;
    max-width: 400px;
  }
  .about-me__image{
        width: 380px;
        height: 250px;
  }
  .h2.deepshadow {
font-size: 15px;
  }
  .about-me__gallery-controls{
  display: none;
  }
  .about-me__next-image {
    width: 70px;
    height: 70px;
}
.about-me__prev-image {
  width: 70px;
  height: 70px;
}
.about-me__text{
  color: #646464;

font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
max-width: 348px;
padding: 0;
margin: 0 auto 20px;
}
.about-me__content{
  flex-direction: column;
  padding: 0;
  max-width: 500px;
}
.about-me__about{
  flex-direction: column;
  margin: 0 auto;
  padding-top: 10px;
}
}
@media screen and (min-width: 768px) and (max-width: 990px) {
.about-me{
  width: 100%;
  padding-top: 100px;
  
}
.about-me__content{
  max-width: 90%;
  padding: 0;
  
}
.about-me__gallery-controls{
  display: none;
}
.about-me__text{
  margin-right: 25px;
  padding: 0;
  font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
}
.about-me__image{
  max-width: 350px;
}
.about-me__about{
  padding-top: 50px;
}
}

@media screen and (min-width: 320px) and (max-width: 399px){
.about-me__content {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 300px;
}
.about-me__image {
 max-width: 300px;
 height: 250px;

}
.about-me__about {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
}
.about-me__title {
max-width: 300px;
color: #13171D;

font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.about-me__text{
  padding: 0;
  margin: 0 auto 20px;
}
.about-me{
  padding-top: 50px;
}
.about-me__prev-button img, 
.about-me__next-button img
.about-me__next-button,
.about-me__prev-button,
.about-me__gallery-controls{
  display: none;
  width: 0px;
  height: 0px;
}
.about-me__gallery-controls{
  display: none;
}
}
@media screen and (min-width: 991px) and (max-width: 1280px){
  .about-me__about{
    max-width: 900px;
    width: 100%;
  }
  .about-me__text{
    padding-top: 0;
  }
}