table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

thead {
  background-color: #f4f4f4;
}

th {
  height: 50px;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}
.glass{
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  position: relative;
}
.glass__title{
    padding: 50px 201px;
    background-color: #f9a92c;
    clip-path: polygon(0 0, 25% 10%, 75% 0, 100% 10%, 100% 85%, 75% 100%, 25% 90%, 0 100%);
    color: #000;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 30px;

}
.glass__sabtitle{
  padding-bottom: 20px;
 
  margin: 0 auto;
  font-family: Gilroy;
  font-size: 10px;
}
.glass__sabtitle-one{
  margin: 0 auto;
  font-family: Gilroy;
  font-size: 10px;
  padding-top: 50px;
  
}
.smaller-font {
  font-family: Gilroy;
  font-size: 0.8em;  
      padding-top: 50px;
    padding-bottom: 50px;
}

.glass__btn{
  margin-top: 10px;
  border-radius: 10px;
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px;
}
.glass__btn:hover{
  background-color: #F9A92C;
}
.glass__btn-active{
  margin-top: 10px;
  border-radius: 10px;
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px;

}
.glass__btn-active:hover{
  background-color: #F9A92C;
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .glass__title{
    max-width: 300px;
    font-size: 13px;
    padding: 10px;
  }
  .smaller-font{
    font-size: 9px;
    text-align: center;
    margin: 0 auto;
  }
}
@media screen and (min-width: 401px) and (max-width: 768px) {
  .glass__title{
    font-family: Gilroy;
    max-width: 300px;
    font-size: 16px;
    padding: 15px;
  }
}