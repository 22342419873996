.list-service{
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
height: 740px;
padding: 10px 50px;
margin: 30px;
border-radius: 6px;
background: white;

}
.list-btn{
        display: flex;
    gap: 20px;
    height: 40px;
}
.list-button{
        padding: 10px;
    background-color: deepskyblue;
}
.listservice-format-main{
display: grid;
grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;
gap: 10px;
width: 100%;
padding: 20px 0px;
color: #454545;
font-size: 15px;
font-weight: 600;
border-bottom: 2px solid cadetblue;
}
.listproduct-format{
align-items: center;
font-weight: 500;

}

.listservice-allservice{
overflow-y: auto;
}
.listservice-service-icon{
height: 80px;

}
.listservice-remove-icon{
cursor: pointer;
margin: auto;
}

@media (max-width: 800px){
    .list-service{
        box-sizing: border-box;
        width: 95%;
        height: 100%;
        padding: 10px 30px;
        margin: 20px auto;
    }
    .listservice-format-main{
        padding: 15px 0px;
        color: #454545;
        font-size: 12px;

    }
    .listservice-service-icon{
        height: 60px;

    }
}