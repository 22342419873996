.cartitems {

    position: relative;
    max-width: 1000px;
    backdrop-filter: blur(2px);
    padding:10px 20px
 
}

.cartitems hr {
    height: 3px;
    background: #e2e2e2;
    border: 0;
        width: 100%;
}

.cartitems-format-main {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 0px;
    color: #454545;
    font-size: 18px;
    font-weight: 600;

}

.cartitems-format {
    font-size: 17px;
    font-weight: 500;
}

.cartitems-product-icon {
    height: 62px;
}

.cartitems-remove-icon {
    width: 15px;
    margin: 0px 40px;
    cursor: pointer;
}

.cartitems-quantity {
    width: 64px;
    height: 50px;
    border: 2px solid #ebebeb;
    background: #fff;
}

.cartitems-down {
    display: flex;
    margin: 100px 0px;
}

.cartitems-total {
    flex: 1;
    display: flex;
    flex-direction: column;
   margin: 0px auto ;
    gap: 40px;
}

.cartitems-total-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
}

.cartitems-total button {
    width: 262px;
    height: 58px;
    outline: none;
    border: none;
    background: #ff5a5a;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.cartitems-promocode {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
}

.cartitems-promocode p {
    color: #555;
}

.cartitems-promobox {
    width: 504px;
    margin-top: 15px;
    padding-left: 20px;
    height: 58px;
    background: #eaeaea;
}

.cartitems-promobox input {
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    width: 330px;
    height: 50px;
}

.cartitems-promobox button {
    width: 170px;
    height: 58px;
    font-size: 16px;
    background: black;
    color: white;
    cursor: pointer;
}
/* Адаптивные стили */
.cartitems{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}
@media (min-width: 320px) and (max-width: 500){
    .cartitems {


    max-width: 300px;
 
}
}
@media (min-width: 501px) and (max-width: 768){
    .cartitems {


    max-width: 500px;
 
}
}
@media screen and (max-width: 1000px) {
    .cartitems {
        margin: 20px;
        max-width: 100%;
    }
    .cartitems-down {
        flex-direction: column;
    }
    .cartitems-total {
        margin: 0;
    }
    .cartitems-promobox {
        width: 300px;
        padding: 0;
    }
    .cartitems-promocode {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cartitems-promobox button {
        margin-top: 40px;
    }

    .cartitems-format-main {
        grid-template-columns: auto 1.5fr 0.75fr auto 1fr auto auto;
        gap: 5px;
        font-size: 14px;
    }

    .cartitems-format {
        grid-template-columns: auto 1.5fr 0.75fr auto 1fr auto auto;
        gap: 5px;
    }

    .cartitems-quantity {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 480px) {
    .cartitems-format-main {
        display: none;
    }
    .cartitems {

    position: relative;
    max-width: 400px;
 
}

    .cartitems-format {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: repeat(4, auto);
        gap: 10px;
        align-items: center;
        padding: 10px 0;
    }

    img {
        grid-row: 1 / span 4;
        width: 50px;
        height: auto;
    }

    .cartitems-format p, .cartitems-format button, .cartitems-format .cartitems-remove-icon {
        grid-column: 2;
            margin: 0 auto;
    }


    .cartitems-quantity, .cartitems-format p {
        font-size: 14px;
    }

    .cartitems-total {
        margin-right: 0;
    }

    .cartitems-promocode {
        margin-top: 20px;
        text-align: center;
    }

    .cartitems-promobox {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .cartitems-promobox input, .cartitems-promobox button {
        margin: 10px 0;
        width: 100%;
    }

    .cartitems-total button {
        width: 100%;
        align-self: center;
    }
}
