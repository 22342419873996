.search__input {
    width: 300px;
    padding: 11px 15px;
    border-color: var(--color-primary);
    border-radius: 0.5rem;
  }
  .search__input:focus {
    outline: var(--color-primary);
  }
  .search{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 400px){
    .search__input{
      width: 250px;
    }
  }
  .search__product{
padding:10px;
  }