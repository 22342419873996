.deleted{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: #b6b6b6;
   width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 30px;
}